
#root {
	display: flex;
	justify-content: center;
}

html,
body,
#root {
  height: calc(100vh - 10px);
}

body {
  color: #040d2c;
  background-color: #f5f5f5;
  border-top: 10px solid #FC3153;
  border-image-source: linear-gradient(-45deg, #be2446 0%, #fc3153 100%);
  border-image-slice: 10;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

input[type='text'] {
  border-radius: 0;
  font-size: 22px;
  line-height: 44px;
  letter-spacing: 1px;
  height: 44px;
  width: 96%;
  border: none;
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  font-size: 22px;
  line-height: 44px;
  letter-spacing: 1px;
  display:flex;
  flex-direction: row;
  justify-content: left;
  border: 1px dashed rgba(04, 13, 44, 0.05);
}

.App {
  width: 450px;
  padding-top: 5em;
  text-align: center;
}

.drop-shadow {
  border: 1px solid rgba(4, 13, 44, 0.2);
  box-shadow: 6px 6px rgba(4, 13, 44, 0.2);
  background-color: #fff;
}

.filters, .filters a {
  color: rgba(4, 13, 44, 0.5);
  text-decoration: none;
  font-size: .9em;
  line-height: 1em;
}

.filters:hover, .filters a:hover {
  color: #fc3153;
}

.filters .current {
  font-weight: bold;
  color: rgba(4, 13, 44, 0.8);
}

.delete {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  content: "\f068";
  height: 44px;
  width: 44px;
  color: rgba(4, 13, 44, 0.15);
  font-size: 30px;
  margin-left: auto;
  cursor: pointer;
  text-decoration: none;
}
.delete:hover {
  color: #fc3153;
}

h1 {
  font-size: 72pt;
  font-family: 'Luckiest Guy';
  color: #aaaaaa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0px -3px 0 #212121, 0px -3px 0 #212121, 0px 3px 0 #212121, 0px 3px 0 #212121, -3px 0px 0 #212121, 3px 0px 0 #212121, -3px 0px 0 #212121, 3px 0px 0 #212121, -3px -3px 0 #212121, 3px -3px 0 #212121, -3px 3px 0 #212121, 3px 3px 0 #212121, -3px 9px 0 #212121, 0px 9px 0 #212121, 3px 9px 0 #212121, 0 19px 0.5px rgba(0, 0, 0, 0.1), 0 0 3px rgba(0, 0, 0, 0.1), 0 3px 1.5px rgba(0, 0, 0, 0.3), 0 6px 1.5px rgba(0, 0, 0, 0.2), 0 9px 9px rgba(0, 0, 0, 0.25), 0 12px 12px rgba(0, 0, 0, 0.2), 0 18px 18px rgba(0, 0, 0, 0.15);
  display: block;
  margin: 22px;
}

/* let's style some checkboxes */
input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {
  width: 0;
  height: 0;
  display: none;
  opacity: 0;
}

input[type="checkbox"]:checked + label, input[type="checkbox"]:not(:checked) + label {
  display: inline-block;
  padding-left: 66px;
  position: relative;
  cursor: pointer;
}
input[type="checkbox"]:checked + label:before, input[type="checkbox"]:not(:checked) + label:before {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  width: 44px;
  height: 44px;
  border: 1px dashed rgba(4, 13, 44, 0.05);
}
input[type="checkbox"]:checked + label:hover:before, input[type="checkbox"]:not(:checked) + label:hover:before {
  background-color: rgba(4, 13, 44, 0.05);
  border-color: rgba(4, 13, 44, 0.05);
}
input[type="checkbox"]:checked:focus + label:befoe, input[type="checkbox"]:not(:checked):focus + label:befoe {
  border-color: rgba(4, 13, 44, 0.5);
  box-shadow: 0 0 6px 0 rgba(4, 13, 44, 0.5);
}
input[type="checkbox"]:checked[disabled] + label, input[type="checkbox"]:not(:checked)[disabled] + label {
  cursor: not-allowed;
  opacity: .4;
}
input[type="checkbox"]:checked[disabled] + label:before, input[type="checkbox"]:not(:checked)[disabled] + label:before {
  opacity: .7;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  font-size: 30px;
  text-align: center;
  transition: border-color .2s ease-in, background-color .2s ease-in;
}

input[type="checkbox"]:checked + label:before {
  content: "\f00c";
  color: #fc3153;
}

input[type="checkbox"]:not(:checked) + label:before {
  content: "";
}

input[type="checkbox"]:checked + label:hover:before {
  color: #fc3153;
}

input[type="checkbox"]:not(:checked) + label:hover:before {
  color: #fff;
  content: "\f00c";
}

